<template>
    <div class="contenedor">

        <rutaPagina :volver="false" :ruta="ruta" />

        <div class="row glass panel">
            <div class="col-lg-6" >
                <div class="imagen-cambiar-clave" />
            </div>
            <div class="col-lg-6" >
                <article class="row">                    
                    <section class="col-lg-10 col-md-12 col-sm-12">
                        <header>Cambiar contraseña</header>
                        <p>
                            Por seguridad su nueva contraseña debe contener por lo menos 8 caracteres incluyendo mayúsculas, minúsculas, números y signos de puntuación, no deben tener acentos ni otros diacríticos, no deben basarse en una palabra que pueda encontrarse en un diccionario y tampoco pueden estar basadas en su nombre ni en su nombre de usuario.
                        </p>
                        <form>
                            <div class="col-md-12 margen-superior">
                                <div class="container-input-usuario">
                                    <input type="password" id="usuario" name="usuario" placeholder="Contraseña actual" v-model="usuario" />
                                    <span><i class="bx bx-lock"></i></span>
                                </div>
                            </div>

                            <div class="col-md-12 margen-superior">
                                <div class="container-input-usuario">
                                    <input type="password" id="usuario" name="usuario" placeholder="Nueva contraseña" v-model="usuario" />
                                    <span><i class="bx bx-lock"></i></span>
                                </div>
                            </div>

                            <div class="col-md-12 margen-superior">
                                <div class="container-input-usuario">
                                    <input type="password" id="usuario" name="usuario" placeholder="Confirmar nueva contraseña" v-model="usuario" />
                                    <span><i class="bx bx-lock"></i></span>
                                </div>
                            </div>

                            <a class="btn btn-primario" >Cambiar contraseña</a>

                        </form>
                    </section>
                </article>
            </div>
        </div>
    </div>
</template>

<script>
import rutaPagina from '@/components/RutaPagina'

export default {
    name: 'Cambiar clave',
    setup(props) {
        const ruta = [
            { nombre : 'Administración'},
            { nombre : 'Cambiar contraseña'},
        ]

        return{
            ruta,
        }
    },
    components : {
        rutaPagina,
    }
}
</script>

<style scoped>
.imagen-cambiar-clave{
    width: 100%;
    height: 27rem;
    background-image: url('../../assets/images/im_cambiar_clave.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}
.margen-superior{
    margin-top: 1rem;
}

.container-input-usuario{
    width: 100%;
    height: 2.6rem;
    background: var(--ng-blanco);
    border-radius: 50px;
    display: flex;
    justify-content: flex-start;
    position: relative;
    outline: none;
    list-style: none;
    margin-bottom: 0.2rem;
    box-shadow: 12px 11px 21px -19px rgb(0, 0, 0 , 1);
}

.container-input-usuario > input {
    border: 1px solid transparent;        
    color: rgba(var(--vs-text),1);
    border-radius: inherit;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    padding: 7px 13px 7px 10px;        
    width: 100%;
    padding-left: 46px;
    outline: none;
    list-style: none;
    background: var(--ng-blanco);
    font-size: 0.8em;
}

.container-input-usuario > span {
    position: absolute;
    right: auto;
    width: 2.9em;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-shadow: 12px 0 10px -10px rgba(0,0,0,var(--vs-shadow-opacity));
    box-shadow: 12px 0 10px -10px rgba(0,0,0,var(--vs-shadow-opacity));
    -webkit-transition: all .25s ease;
    transition: all .25s ease;
    border-radius: inherit;
    background: rgba(var(--vs-gray-2),1);
    pointer-events: none;
    left: 0;
    outline: none;
    list-style: none;
    transition: all 0.2s ease-out;
}

.container-input-usuario > span > i {
    font-size: 1.2em;
}
section{
    margin-top: 2rem;
    margin-bottom: 2rem;
}
section > header {
    font-weight: bold;
    font-size: 2rem;
    text-align: center;
    margin-top: 1rem;
}
section > p {
    font-size: 0.86rem;
    text-align: justify;
    margin-top: 1rem;
}
section > a {
    width: 100%;
    color: var(--ng-blanco);
}
.btn-primario, .btn-primario:hover {
    background-color: var(--ng-primario) !important;
    box-shadow: 0 2px 4px rgb(7 95 255 / 40%) !important;
    border: 1px solid rgb(7 95 254) !important;
    color: var(--ng-blanco);
    width: 100%;
    margin-top: 1.4rem;
}
@media (max-width: 782px) {
    .imagen-cambiar-clave{
        height: 18rem;
    }
}
@media (max-width: 420px) {
    .imagen-cambiar-clave{
        height: 15rem;
    }
    section {
        margin-top: 1rem;
    }
    section > header {
        margin-top: 0px;
    }
}
</style>